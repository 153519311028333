<template>
    <transition name="slide">
          <CCard>
            <CCardBody>

              <CButton color="primary" @click="create()"
                ><CIcon name="cil-plus" /> Add new closure</CButton
              >
              <br />
              <br />
              <br />
              <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
              <CDataTable hover :items="items" :fields="fields" :items-per-page="50" pagination>
                <template #experiences="{ item }">
                  <td style="width: 40%">
                    <ejs-tooltip ref="tooltip" :content="getExperiencesString(item, true)" >
                      <span>{{ getExperiencesString(item, false) }}</span>
</ejs-tooltip>
                      
                    </td>
                  
                </template>
                <template #startTime="{ item }">
                  <td>
                    {{ moment(new Date(item.startTime)).format($dateFormat) }}
                  </td>
                </template>
                <template #endTime="{ item }">
                  <td>
                    {{ moment(new Date(item.endTime)).format($dateFormat) }}
                  </td>
                </template>
                <template #Edit="{ item }">
                  <td>
                    <CButton color="primary"  @click="edit(item.id)"><CIcon name="cil-pencil" /> Edit</CButton>
                  </td>
                </template>
                <template #Delete="{ item }">
                  <td>
                    <CButton color="danger" @click="deleteMethod(item.id)">Delete</CButton
                    >
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
        </transition>
  </template>
  
  <script>
  import axios from "axios";
  import { TooltipComponent } from "@syncfusion/ej2-vue-popups";

  export default {
    name: "Closures",
    data: () => {
      return {
        token: localStorage.getItem("api_token"),
        partnerId: localStorage.getItem("partner_id"),
        emptyGuid: "00000000-0000-0000-0000-000000000000",
        fields: ["name", "experiences", "startTime", "endTime", "Edit", "Delete"],  
        items: [],
        experiences: null,
        //Alert
        alertType: "danger",
        message: null,
      };
    },
    components: {
      'ejs-tooltip': TooltipComponent
  },
    computed: {},
    methods: {
      getExperiences() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/ExperienceList")
        .then(function (response) {
          self.experiences = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
      deleteMethod(id) {
        let self = this;
        axios
          .delete(
            `${this.$apiAdress}/v1/Closures/${id}`
          )
          .then(function () {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully deleted closure.";
            self.getList();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      create() {
        this.$router.push({ path: `closures/create/${this.emptyGuid}` });
      },
      edit(id){
        this.$router.push({path: `closures/create/${id}`})
      },

      getList() {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/Closures`)
          .then(function (response) {
            self.items = response.data;
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      getExperiencesString(closure, isFullLength){
        var result = '';
        if(closure.experiences.find(experience => experience == this.emptyGuid)){
          return "All experiences";
        }
        closure.experiences.forEach(closureExperience => {
          if(result == '')
            result += this.experiences.find(experience => experience.value == closureExperience).label;
          else
            result = result + ", " + this.experiences.find(experience => experience.value == closureExperience).label;
        });
        if(isFullLength)
          return result;
        else if(result.length > 400)
          return result.slice(0, 400) + "...";
        return result;
      }
    },
    mounted: function () {
      this.getExperiences();
      this.getList();
    },
  };
  </script>
  
  <style scoped>
  .card-body >>> table > tbody > tr > td {
    cursor: pointer;
  }
  </style>
  